<template>
  <div class="content-box">
    <div class="content">
      <div class="policylib-result express">
        <el-tabs v-model="activeName">
          <el-tab-pane label="我发布的招聘列表" name="first">
            <!-- 筛选 -->
            <div class="filters-list">
              <ul class="filters">
                <li
                  class="fitler-item"
                  v-for="(fitler, goodsIndex) in fitlerList"
                  :key="goodsIndex"
                >
                  <span class="nameFrame">{{ fitler.title }}</span>
                  <ul class="cates">
                    <li
                      :class="{
                        active: typeIndex === fitler.index,
                      }"
                      v-for="(type, typeIndex) in fitler.typeList"
                      :key="typeIndex"
                      @click="handleclick(typeIndex, fitler, type, goodsIndex)"
                    >
                      {{ type.name }}
                    </li>
                  </ul>
                </li>
              </ul>
              <!-- 已经选择： -->
              <ul class="filters">
                <li class="fitler-item">
                  <span class="nameFrame">已经选择：</span>
                  <ul class="cates">
                    <li
                      class="filtered-item"
                      v-for="(sureItem, index) in FilterList"
                      :key="index"
                    >
                      {{ sureItem.name }}
                      <i class="el-icon-close" @click="minItemClick(index)"></i>
                    </li>

                    <li class="clear-all-filtered" @click="resetClick">
                      清除筛选
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="pane">
              <ul>
                <li
                  class="pane-list"
                  v-for="item in recruitList"
                  :key="item.index"
                >
                  <div class="pane-text" @click="handleXq(item)">
                    <div class="pane-text-left">
                      <h3 class="pane-title">{{ item.job_name }}</h3>
                      <div class="headers">
                        <span class="red"> {{ item.salary }} </span
                        ><span>{{ item.rens ? item.rens.name : "无" }}</span>
                        <span>{{ item.degree ? item.degree.name : "无" }}</span>
                        <span
                          >{{ item.education ? item.education.name : "无" }}
                        </span>
                      </div>
                    </div>
                    <div class="pane-text-center">
                      <h3 class="pane-title" style="margin-left: 30px">
                        {{ item.company_name }}
                      </h3>
                    </div>
                    <div class="pane-text-right">
                      <img
                        src="../../../assets/img/huiyuanzhongxin/l.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="pane-remarks">
                    <span>备注：</span><span>{{ item.job_describe }}</span>
                  </div>
                </li>
              </ul>

              <div class="pagination">
                <pagination
                  v-show="total > 0"
                  style="padding: 0"
                  class="pages"
                  :total="total"
                  layout="total,  prev, pager, next, jumper"
                  :page.sync="listQuery.page"
                  :limit.sync="listQuery.limit"
                  @pagination="getRecruitList"
                />
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 弹出框 -->
    <el-dialog
      class="dialog"
      :visible.sync="dia.dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <Xq :item="dia"></Xq>
    </el-dialog>
  </div>
</template>

<script>
import {
  RecruitList,
  Industry,
  Degree,
  Education,
} from "../../../common/js/api";
import Xq from "./xq/test1-4";

import Pagination from "@/common/Pagination";

export default {
  components: { Pagination, Xq },
  data() {
    return {
      activeName: "first",
      fitlerList: [
        {
          title: "学历要求：",
          typeList: [],
        },
        {
          title: "学位要求：",
          typeList: [],
        },
        {
          title: "行业领域：",
          typeList: [],
        },
      ], //筛选
      FilterList: {
        0: { name: "不限" },
        1: { name: "不限" },
        2: { name: "不限" },
      }, //已经选择数据
      FilterListId: {}, //已经选择数据id
      recruitList: [],
      total: 0, //总条目数
      listQuery: {
        limit: 4,
        page: 1,
      },
      dia: {
        dialogVisible: false,
        id: "",
      },
    };
  },
  created() {
    this.fitlerList.forEach((item) => this.$set(item, "index", 0));
    this.getRecruitList();
    this.getIndustry();
    this.getDegree();
    this.getEducation();
  },
  methods: {
    // 详情
    handleXq(item) {
      this.dia.dialogVisible = true;
      this.dia.id = item.id;
    },
    // 列表
    getRecruitList() {
      this.getList(this.FilterListId);
    },
    // 筛选
    handleclick(typeIndex, goods, type, goodsIndex) {
      goods.index = typeIndex;

      this.$set(this.FilterList, goodsIndex, type);

      this.$set(this.FilterListId, goodsIndex, type.id);

      this.getList(this.FilterListId);
    },
    // 已选择每项的x键
    minItemClick(minIndex) {
      this.$delete(this.FilterList, minIndex);
      this.$delete(this.FilterListId, minIndex);
      this.fitlerList[minIndex].index = 0;
      this.getList(this.FilterListId);
    },
    /* 清除筛选 */
    resetClick() {
      for (var i in this.fitlerList) {
         this.fitlerList[i].index = 0;
      }
      this.FilterList = {
        0: { name: "不限" },
        1: { name: "不限" },
        2: { name: "不限" },
      };
      this.FilterListId = {};
      this.getList(this.FilterListId);
    },

    getList(IdList) {
      RecruitList({
        member_id:  JSON.parse(localStorage.getItem("user")).id,
        page: this.listQuery.page,
        limits: this.listQuery.limit,
        territory: IdList[2],
        education: IdList[0],
        degree: IdList[1],
      })
        .then((res) => {
          this.recruitList = res.data.data;
          this.total = res.data.total;
        })
        .catch(() => {});
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    // 行业领域
    getIndustry() {
      Industry()
        .then((res) => {
          var b = { name: "不限" };
          res.data.unshift(b);
          this.fitlerList[2].typeList = res.data;
        })
        .catch(() => {});
    },
    // 学位
    getDegree() {
      Degree()
        .then((res) => {
          var b = { name: "不限" };
          res.data.unshift(b);
          this.fitlerList[1].typeList = res.data;
        })
        .catch(() => {});
    },
    // 学历
    getEducation() {
      Education()
        .then((res) => {
          var b = { name: "不限" };
          res.data.unshift(b);
          this.fitlerList[0].typeList = res.data;
        })
        .catch(() => {});
    },
  },
};
</script>

 <style scoped src="@/assets/css/page.css"></style>
<style lang="less" scoped>
.el-input-group {
  width: 603px;
  border-radius: 10px;
}
/deep/.el-input-group__append {
  background: #3892eb;
  color: #fff;
}

/deep/.el-tabs__header {
  padding: 0 43px;
}
/deep/.el-tabs__nav-wrap::after {
  background-color: transparent;
}

.pane {
  margin: 20px 100px 0 43px;
  background-color: #fff;
  padding: 27px 75px 20px 63px;
  ul {
    padding: 1px;
    .pane-list {
      position: relative;
      border: 1px solid #bbb;
      border-radius: 5px;
      margin-bottom: 20px;
      height: 125px;
      .pane-text {
        display: flex;
        justify-content: space-between;
        padding: 10px 100px 0 45px;

        .pane-title {
          font-size: 20px;
          color: #101010;
          margin: 10px 0;
          cursor: pointer;
        }
        .headers {
          display: flex;
          span {
            margin-right: 35px;
            font-size: 12px;
            color: #101010;
            line-height: 32px;
            cursor: pointer;
          }
          .red {
            color: #ff0000;
          }
        }
        .pane-text-right {
          width: 130px;
          height: 80px;
          cursor: pointer;
        }
      }
      .pane-remarks {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: #e8effb;
        padding: 10px 45px;
        color: #626262;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.pagination {
  border: 0;
  background-color: #fff;
}
// 弹出框
.dialog {
  /deep/.el-dialog__body {
    padding: 20px 100px 50px;
  }
}
</style>