<template>
  <div class="wraps-iim">
    <div class="topeach-policy-list">
      <span class="info-tips"> {{ xq.job_name }}</span>
      <span class="info-tips-red"> {{ xq.salary }}</span>
    </div>
    <div class="topeach-policy-list">
      <span class="left-info-tips"
        ><span> {{ xq.nature ? xq.nature.name  :'无'}} </span><span> {{ xq.rens ? xq.rens.name  :'无'}}</span>
        <span > {{ xq.education ? xq.education.name  :'无'}} </span><span> {{ xq.degree ? xq.degree.name  :'无'}}</span></span
      >
      <span class="left-info-tips" style="color: #b1afaf">
        {{ xq.job_describe }}</span
      >
    </div>

    <div class="wrap-all-tipbox">
      <div class="left-info-title">岗位职责</div>

      <div class="wrap-tiptit-box">
        <p>
          {{xq.duty? xq.duty  :'无'}}
        </p>
      </div>
      <div class="left-info-title">岗位要求</div>

      <div class="wrap-tiptit-box">
        <p>
          {{xq.require ? xq.require  :'无'}}
        </p>
      </div>
      <div class="left-info-title">专业技术职称要求</div>

      <div class="wrap-tiptit-box">
        <p>
          无
        </p>
      </div>
      <div class="left-info-title">专业技术资格要求</div>

      <div class="wrap-tiptit-box">
        <p>
          无
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { RecruitXq } from "@/common/js/api";
import { formatDate } from "@/common/js/times"; //时间
export default {
  filters: {
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd");
    },
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      id: "",
      xq: {},
    };
  },
  watch: {
    item: {
      handler(val, oldVal) {
        if (this.item.dialogVisible == true && this.id != val.id) {
          this.id = val.id;
          this.RecruitXq(val.id);
        }
      },
      deep: true,
    },
  },
  created() {
    this.id = this.item.id;
    this.RecruitXq(this.id);
  },
  methods: {
    //  我发布的成果详情
    RecruitXq(id) {
      RecruitXq({
        id: id,
      })
        .then((res) => {
          console.log(res);
          this.xq = res.data;
         })
        .catch(() => {});
    },
  },
};
</script>
<style lang="less" scoped>
.topeach-policy-list {
  font-size: 12px;
  .info-tips {
    color: #000;
    font-weight: 700;
    font-size: 16px;
  }
  .info-tips-red {
    color: #ff0000;
    margin-left: 20px;
  }
  .left-info-tips {
    display: flex;
    align-items: center;
    margin: 5px 0 10px;
    line-height: 1.3;
    span {
      border: 1px solid #b1afaf;
      margin-right: 10px;
      padding: 3px 5px;
    }
  }
}

.left-info-title {
  display: inline-block;
  margin: 25px 0 10px;
  color: #3a93eb;
  border: 1px solid #3a93eb;
  border-radius: 20px;
  padding: 5px 10px;
}

.wrap-tiptit-box {
  line-height: 1.5;
  color: #010101;
}
</style>